var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"footer-props":{
    itemsPerPageOptions: [25, 50, 100, -1],
  },"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(!_vm.items)?_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("query_builder")]),_vm._v("Waiting for query")],1):_c('div',[_c('v-icon',{attrs:{"color":"warning","left":""}},[_vm._v("warning")]),_c('span',{staticClass:"warning--text"},[_vm._v("There is no data for this tag")])],1)]},proxy:true},{key:`item.tagKey1`,fn:function({ item }){return [_c('router-link',{attrs:{"to":`/explore/tagkeys/${encodeURIComponent(
        item.tagKey1.tagKey
      )}/tag/${encodeURIComponent(item.tagKey1.tagKey)}:${encodeURIComponent(
        item.tagKey1.tagValue
      )}`}},[_vm._v(_vm._s(item.tagKey1.tagValue))])]}},{key:`item.tagKey2`,fn:function({ item }){return [_c('router-link',{attrs:{"to":`/explore/tagkeys/${encodeURIComponent(
        item.tagKey2.tagKey
      )}/tag/${encodeURIComponent(item.tagKey2.tagKey)}:${encodeURIComponent(
        item.tagKey2.tagValue
      )}`}},[_vm._v(_vm._s(item.tagKey2.tagValue))])]}},{key:`item.tagKey3`,fn:function({ item }){return [_c('router-link',{attrs:{"to":`/explore/tagkeys/${encodeURIComponent(
        item.tagKey3.tagKey
      )}/tag/${encodeURIComponent(item.tagKey3.tagKey)}:${encodeURIComponent(
        item.tagKey3.tagValue
      )}`}},[_vm._v(_vm._s(item.tagKey3.tagValue))])]}},_vm._l((_vm.usageHeaders),function(slot){return {key:`item.${slot.value}`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.figures[slot.value],_vm.currencyCode))+" ")]}}})],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }