
import { useTenantStore, useWaitStore, storeToRefs } from '@/stores';
import SpendUsageBarChart, {
    convertProjectionsResponseToDataset,
    convertUsageResponseToDatasets,
    IClickEvent,
    UsageReportTypes,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import SpendUsageOptionsToolbarForm, {
    SpendUsageOptions,
    CostView,
    convertSpendOptionsToQuery,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import UsageFilterChips, {
    filterUsageParamsByFilterChips,
    IChipViewModel,
} from '@/components/Domain.Usage/UsageFilterChips.vue';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import { toastError } from '@/components/Common/Toast.vue';
import { defineComponent, ref, watch } from 'vue';
import { getSpend, getForecast } from '@/lib/Api';
import { fromPlural } from '@/models/EntityType';
import {
    EntityType,
    PluralEntityType,
    ICustomViewResult,
    Granularity,
    ISpendUsageTableRecordsViewModel,
SpendFieldNames,
} from '@/models';
import { i18n } from '@/i18n';
import { appendValueToCommaString } from '@/lib';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';
export default defineComponent({
    components: {
        SpendUsageOptionsToolbarForm,
        UsageFilterChips,
        SpendUsageTable,
        SpendUsageBarChart,
    },
    props: { value: Object, loading: Boolean },
    setup(p) {
        const { currencyCode } = storeToRefs(useTenantStore());
        const { is, whilst } = useWaitStore();
        const spendOptions = ref<SpendUsageOptions>({
            dateRangeKey: '6m',
            granularity: Granularity.monthly,
            topXResults: 10,
            segregateBy: PluralEntityType.productCategories,
        });
        const newChartData = ref({ labels: [], datasets: [] });
        const usageTableItems = ref<ISpendUsageTableRecordsViewModel | null>(null);
        const filters = ref<IChipViewModel[]>([]);
        const searchSupportedTypes = ref<EntityType[]>([
            EntityType.subscription,
            EntityType.service,
            EntityType.product,
            EntityType.tag,
        ]);
        const segregationSupportedKeys = ref<PluralEntityType[]>([
            PluralEntityType.productCategories,
            PluralEntityType.subscriptions,
            PluralEntityType.services,
        ]);

        const loadTabUsage = async () =>
            await whilst('getAccountUsage', async () => {
                const view = p.value as ICustomViewResult;
                let params = convertSpendOptionsToQuery(spendOptions.value);
                if (view.cloudAccountIds.length) {
                    params.cloudAccountIds = appendValueToCommaString(
                        params.cloudAccountIds,
                        view.cloudAccountIds.map((a) => a.id)
                    );
                }
                if (view.cloudProviderTypes.length) {
                    params.providerType = appendValueToCommaString(params.providerType, view.cloudProviderTypes);
                }
                if (view.geographies.length) {
                    params.geographyNames = appendValueToCommaString(params.geographyNames, view.geographies);
                }
                if (view.productCategories.length) {
                    params.productCategories = appendValueToCommaString(
                        params.productCategories,
                        view.productCategories.map((p) => `"${p}"`)
                    );
                }
                if (view.regions.length) {
                    params.regionNames = appendValueToCommaString(params.regionNames, view.regions);
                }
                if (view.subscriptionIds) {
                    params.subscriptionIds = appendValueToCommaString(
                        params.subscriptionIds,
                        view.subscriptionIds.map((s) => s.id)
                    );
                }
                if (view.sysProductIds) {
                    params.productIds = appendValueToCommaString(
                        params.productIds,
                        view.sysProductIds.map((p) => p.id)
                    );
                }
                if (view.sysServiceIds) {
                    params.serviceIds = appendValueToCommaString(
                        params.serviceIds,
                        view.sysServiceIds.map((s) => s.id)
                    );
                }
                if (view.tagKeyValues) {
                    params.tagKeyValue = appendValueToCommaString(params.tagKeyValue, view.tagKeyValues);
                }
                if (view.tagKeys) {
                    // TODO
                }
                if (Array.isArray(spendOptions.value.segregateBy)) throw new Error('Multiple segregations unsupported!');
                const segregateBy = spendOptions.value.segregateBy;
                params = filterUsageParamsByFilterChips(filters.value, params);
                if (!params) return;
                const [usageResponse, projectionsResponse] = await Promise.all([
                    getSpend({ ...params, segregateBy, fields: [ SpendFieldNames.Charges ] }),
                    getForecast(params, spendOptions.value.dateRangeKey),
                ]);
                const usageDataset = convertUsageResponseToDatasets(usageResponse, { reportType: UsageReportTypes.charges });
                const forecastDataset = convertProjectionsResponseToDataset(projectionsResponse);
                newChartData.value = {
                    labels: [...forecastDataset.labels, ...usageDataset.labels],
                    datasets: [...forecastDataset.datasets, ...usageDataset.datasets],
                };
                usageTableItems.value = SpendUsageTableFunctions.convertToItemRecords(
                    params,
                    segregateBy,
                    usageResponse,
                    spendOptions.value.costView
                );
            });

        const handleDatasetClicked = ({ isOther, name, datasetGroupId }: IClickEvent) => {
            if (isOther) {
                spendOptions.value.topXResults = spendOptions.value.topXResults + 10;
            } else if (spendOptions.value.segregateBy === PluralEntityType.meters) {
                toastError(i18n.t('shared.meterInformationNotSupported'));
            } else if (!Array.isArray(spendOptions.value.segregateBy)) {
                filters.value.push({ id: datasetGroupId, name, type: fromPlural(spendOptions.value.segregateBy) as EntityType });
            }
        };

        watch(
            () => [p.value, spendOptions.value, filters.value],
            () => {
                if (!p.value || !spendOptions.value || is('getAccountUsage')) return;
                loadTabUsage();
            },
            { immediate: true, deep: true }
        );

        return {
            spendOptions,
            newChartData,
            usageTableItems,
            filters,
            searchSupportedTypes,
            segregationSupportedKeys,
            loadTabUsage,
            handleDatasetClicked,
            currencyCode,
            wait: { is },
        };
    },
});
