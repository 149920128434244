
import { defineComponent, ref, onMounted, watch } from 'vue';
import createEmissionBarChart, {
    convertCO2eResponseToDatasets,
    convertProjectionsResponseToDataset,
    convertUsageResponseToEmissionsDatasets,
    IClickEvent,
    ReverseLookup,
} from '@/chartjs/createEmissionBarChart';
import { Granularity } from '@/models';
export {
    convertCO2eResponseToDatasets,
    convertProjectionsResponseToDataset,
    convertUsageResponseToEmissionsDatasets,
    IClickEvent,
    ReverseLookup,
};

export default defineComponent({
    props: {
        granularity: String,
        data: Object,
        height: String,
    },
    setup(p, { emit }) {
        const root = ref<HTMLDivElement>(null);
        const canvas = ref<HTMLCanvasElement>(null);
        const legend = ref<HTMLDivElement>(null);
        const canvasContainer = ref<HTMLDivElement>(null);

        const renderChart = () => {
            if (!p.data) return;
            if (!root.value) return;
            root.value.innerHTML = "";
            root.value.classList.add('chart-container');

            canvasContainer.value = document.createElement('div');
            canvasContainer.value.classList.add('chart-canvas-container');

            canvas.value = document.createElement("canvas");
            canvas.value.classList.add('chart-canvas');
            if (p.height === '100%') {
                canvas.value.classList.add('fill-height-srsly');
                root.value.classList.add('fill-height');
                canvasContainer.value.classList.add('fill-height-srsly');
            } else {
                canvas.value.style.height = p.height || '400px';
            }

            legend.value = document.createElement('div');
            legend.value.id =  Math.floor(Math.random() * 1000000).toString();
            legend.value.innerHTML ="";
            legend.value.classList.add('chart-legend');
            legend.value.style.maxHeight = p.height || '400px';
            
            canvasContainer.value.appendChild(canvas.value);
            root.value.appendChild(canvasContainer.value);
            root.value.appendChild(legend.value);
            const chart = createEmissionBarChart(canvas.value, {
                granularity: p.granularity as Granularity.daily | Granularity.monthly,
                onClick(event) {
                    emit('click', event);
                },
            },
            legend.value.id);
            chart.data = p.data as any;
            chart.update();
        };
        onMounted(() => {
            renderChart();
        });
        watch(
            () => p.data,
            () => renderChart(),
            { immediate: true, deep: true }
        );
        return { root, canvas, renderChart };
    },
    watch: {
        '$vuetify.theme.dark': {
            handler() {
                this.renderChart();
            },
        },
    },
});
