import Vue from 'vue';
import VueRouter from 'vue-router';
import MainView from '@/views/MainView.vue';
import FinanceDashboard from '@/views/FinanceDashboard.vue';
import TrendsDashboard from '@/views/TrendsDashboard.vue';
import ReservationsDashboard from '@/views/ReservationsDashboard.vue';
import RecommendationsView from '@/views/Recommendations.vue';
import KubernetesDashboard from '@/views/KubernetesDashboard.vue';
import Passthrough from '@/views/Passthrough.vue';

import TagDashboardView from '@/views/Reports/TagOverview.vue';
import CustomersUsageView from '@/views/Reports/CustomersUsage.vue';
import RegionOverviewView from '@/views/Reports/RegionOverview.vue';

import CarbonOverview from '@/views/CarbonFootprint/Overview.vue';
import CarbonExplorer from '@/views/CarbonFootprint/Explorer.vue';
import CarbonMap from '@/views/CarbonFootprint/Map.vue';

import CustomersManageView from '@/views/CustomersManage/CustomersManage.vue';
import CustomerManageView from '@/views/CustomersManage/CustomerManage.vue';

import Settings from '@/views/Settings/IndexView.vue';
import Amazon from '@/views/Settings/AmazonView.vue';
import Azure from '@/views/Settings/AzureView.vue';
import Google from '@/views/Settings/GoogleView.vue';
import Oracle from '@/views/Settings/OracleView.vue';
import Alibaba from '@/views/Settings/AlibabaView.vue';
import Snowflake from '@/views/Settings/SnowflakeView.vue';
import Custom from '@/views/Settings/CustomView.vue';
import Users from '@/views/Settings/UsersView.vue';
import BillingDetails from '@/views/Settings/BillingDetailsView.vue';
import ApiManagementView from '@/views/Settings/ApiManagementView.vue';
import General from '@/views/Settings/GeneralView.vue';
const Actions = () => import(/* webpackChunkName: "actions" */ '@/views/Settings/ActionsView.vue');
import FxRates from '@/views/Settings/FxRatesView.vue';
import TagMappings from '@/views/Settings/TagMappings.vue';
import MailingLists from '@/views/Settings/MailingLists.vue';

import ChooseView from '@/views/Auth/Choose.vue';

import SelfServiceTenantCreate from '@/views/SelfService/CreateTenant.vue';
import SelfServicePlans from '@/views/SelfService/SelectPlan.vue';
import ResourceExplorerIndex from '@/views/ResourceExplorer/Index.vue';

import ServiceDialog from '@/views/Dialog.Service/Dialog.vue';
import ServiceDialogIndex from '@/views/Dialog.Service/Index.vue';
import ServiceDialogAnnotations from '@/views/Dialog.Service/Annotations.vue';
import ServiceDialogRecordTags from '@/views/Dialog.Service/RecordTags.vue';
import ServiceDialogVirtualTags from '@/views/Dialog.Service/VirtualTags.vue';
import ServiceDialogUsage from '@/views/Dialog.Service/Usage.vue';
import ServiceDialogCarbon from '@/views/Dialog.Service/Carbon.vue';
import ServiceDialogRawUsage from '@/views/Dialog.Service/RawUsage.vue';

import SubscriptionDialog from '@/views/Dialog.Subscription/Dialog.vue';
import SubscriptionDialogIndex from '@/views/Dialog.Subscription/Index.vue';
import SubscriptionDialogTrends from '@/views/Dialog.Subscription/Trends.vue';
import SubscriptionDialogUsage from '@/views/Dialog.Subscription/Usage.vue';
import SubscriptionDialogCarbon from '@/views/Dialog.Subscription/Carbon.vue';
import SubscriptionDialogAnnotations from '@/views/Dialog.Subscription/Annotations.vue';
import SubscriptionDialogVirtualTags from '@/views/Dialog.Subscription/VirtualTags.vue';

import ProductDialog from '@/views/Dialog.Product/Dialog.vue';
import ProductDialogIndex from '@/views/Dialog.Product/Index.vue';
import ProductDialogUsage from '@/views/Dialog.Product/Usage.vue';
import ProductDialogCarbon from '@/views/Dialog.Product/Carbon.vue';
import ProductDialogTrends from '@/views/Dialog.Product/Trends.vue';

import RegionDialog from '@/views/Dialog.Region/Dialog.vue';
import RegionDialogIndex from '@/views/Dialog.Region/Index.vue';
import RegionDialogUsage from '@/views/Dialog.Region/Usage.vue';
import RegionDialogCarbon from '@/views/Dialog.Region/Carbon.vue';

import ProductCategoryDialog from '@/views/Dialog.ProductCategory/Dialog.vue';
import ProductCategoryDialogIndex from '@/views/Dialog.ProductCategory/Index.vue';
import ProductCategoryDialogUsage from '@/views/Dialog.ProductCategory/Usage.vue';

import CloudProviderDialog from '@/views/Dialog.CloudProvider/Dialog.vue';
import CloudProviderDialogIndex from '@/views/Dialog.CloudProvider/Index.vue';
import CloudProviderDialogUsage from '@/views/Dialog.CloudProvider/Usage.vue';

import GeographyDialog from '@/views/Dialog.Geography/Dialog.vue';
import GeographyDialogIndex from '@/views/Dialog.Geography/Index.vue';
import GeographyDialogUsage from '@/views/Dialog.Geography/Usage.vue';
import GeographyDialogCarbon from '@/views/Dialog.Geography/Carbon.vue'

import TagDialog from '@/views/Dialog.Tag/Dialog.vue';
import TagDialogIndex from '@/views/Dialog.Tag/Index.vue';
import TagDialogUsage from '@/views/Dialog.Tag/Usage.vue';
import TagDialogCarbon from '@/views/Dialog.Tag/Carbon.vue';
import TagDialogTrends from '@/views/Dialog.Tag/Trends.vue';

import CloudAccountDialog from '@/views/Dialog.CloudAccount/Dialog.vue';
import CloudAccountDialogIndex from '@/views/Dialog.CloudAccount/Index.vue';
import CloudAccountDialogCopies from '@/views/Dialog.CloudAccount/Copies.vue';
import CloudAccountDialogAnnotations from '@/views/Dialog.CloudAccount/Annotations.vue';
import CloudAccountDialogUsage from '@/views/Dialog.CloudAccount/Usage.vue';
import CloudAccountDialogTrends from '@/views/Dialog.CloudAccount/Trends.vue';
import CloudAccountDialogVirtualTags from '@/views/Dialog.CloudAccount/VirtualTags.vue';
import CloudAccountDialogCarbon from '@/views/Dialog.CloudAccount/Carbon.vue';

import ReservationDialog from '@/views/Dialog.Reservation/Dialog.vue';
import ReservationDialogIndex from '@/views/Dialog.Reservation/Index.vue';
import ReservationDialogUsage from '@/views/Dialog.Reservation/Usage.vue';

const ActionDialog = () => import(/* webpackChunkName: "actions" */ '@/views/Dialog.Action/Dialog.vue');
const ActionDialogIndex = () => import(/* webpackChunkName: "actions" */ '@/views/Dialog.Action/Index.vue');
const ActionDialogVersions = () => import(/* webpackChunkName: "actions" */ '@/views/Dialog.Action/Versions.vue');
const ActionDialogAccounts = () => import(/* webpackChunkName: "actions" */ '@/views/Dialog.Action/CloudAccounts.vue');

const ActionIndex = () => import(/* webpackChunkName: "actions" */ '@/views/Action/Index.vue');
const ActionDevelop = () => import(/* webpackChunkName: "actions" */ '@/views/Action/Develop.vue');
const ActionVersions = () => import(/* webpackChunkName: "actions" */ '@/views/Action/Versions.vue');
const ActionCloudAccounts = () => import(/* webpackChunkName: "actions" */ '@/views/Action/CloudAccounts.vue');

import CopyCloudAccountIndex from '@/views/CopyCloudAccount/Index.vue';
import CopyCloudAccountChooseTenant from '@/views/CopyCloudAccount/ChooseTenant.vue';
import CopyCloudAccountFinish from '@/views/CopyCloudAccount/Finish.vue';
import CopyCloudAccountPricingRules from '@/views/CopyCloudAccount/PricingRules.vue';
import CopyCloudAccountTags from '@/views/CopyCloudAccount/Tags.vue';
import NotFound from '@/views/NotFound.vue';

import CustomViewDialog from '@/views/Dialog.CustomView/Dialog.vue';
import CustomViewDialogIndex from '@/views/Dialog.CustomView/Index.vue';
import CustomViewDialogUsage from '@/views/Dialog.CustomView/Usage.vue';

import CustomReports from '@/views/Reports/CustomReports.vue';
import CustomReport from '@/views/Reports/CustomReport.vue';
import CreateReport from '@/views/Reports/CreateReport.vue';
import EditReport from '@/views/Reports/EditReport.vue';
import CloneReport from '@/views/Reports/CloneReport.vue';
import CustomViews from '@/views/CustomViews/CustomViews.vue';
import CustomView from '@/views/CustomViews/CustomView.vue'
import CustomViewOverview from '@/views/CustomViews/Overview.vue'
import Budgets from '@/views/Budgets/Budgets.vue';
import BudgetDetails from '@/views/Budgets/BudgetDetails.vue';

import GptChats from '@/views/Intelligence/GptChats.vue';
import GettingStarted from '@/views/GettingStarted.vue';

import WatchdogDashboard from '@/views/Watchdog/Index.vue';
import WatchdogViewPolicy from '@/views/Watchdog/ViewPolicy.vue';
import WatchdogViewPolicyRun from '@/views/Watchdog/ViewPolicyRun.vue';
import WatchdogNewPolicy from '@/views/Watchdog/NewPolicy.vue';

import CustomDashboardsIndex from '@/views/CustomDashboards/Index.vue';
import CustomDashboardsDashboard from '@/views/CustomDashboards/Dashboard.vue';

import EventBus from '@/lib/EventBus';
import AuthClient from '@/lib/Auth';
import Raygun from '@/lib/Raygun';
import Config from '@/lib/Config';

import { authGuard } from '@/plugins/auth/authGuard';
import { getInstance } from './auth/auth0Instance';
import { validate as uuidValidate } from 'uuid';

Vue.use(VueRouter);

export const PATH_RESOURCES = {
    names: {
        confirm: 'confirm',
        logout: 'logout',
        settings: 'settings',
        choose: 'choose',
        dash: 'dash',
        trends: 'trends',
        reservations: 'reservations',
        customersUsageDashboard: 'customersUsageDashboard',
        customersManage: 'customersManage',
        customerManage: 'customerManage',
        createEditSharedData: 'createEditSharedData',
        recommendations: 'recommendations',
        tagoverview: 'tagoverview',
        regionoverview: 'regionoverview',
        copyCloudAccount: 'copy-cloudaccount',
        billingdetails: 'billingdetails',
        powerBiReport: 'powerbireport',
        cloudAccounts: 'cloudaccounts',
        users: 'users',
        tenants: 'tenants',
        actions: 'actions',
        fxrates: 'fxrates',
        resources: 'resources',
        ApiManagement: 'apiManagement',
        createTenant: 'create-tenant',
        reports: 'reports',
        selfServiceCreate: 'selfservice-create',
        selfServicePlans: 'selfservice-plans'
    }
};

const serviceDialogChildRoutes = [
    {
        path: '',
        component: Passthrough
    },
    {
        path: ':serviceId',
        component: ServiceDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            {
                path: 'usage',
                component: ServiceDialogUsage
            },
            { path: 'annotations', component: ServiceDialogAnnotations },
            { path: 'tags', component: ServiceDialogRecordTags },
            { path: 'virtualtags', component: ServiceDialogVirtualTags },
            { path: 'raw', component: ServiceDialogRawUsage },
            { path: 'carbon', component: ServiceDialogCarbon }
        ]
    }
];

const subscriptionDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: ':subscriptionId',
        component: SubscriptionDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: SubscriptionDialogUsage },
            { path: 'carbon', component: SubscriptionDialogCarbon },
            { path: 'trends', component: SubscriptionDialogTrends },
            { path: 'annotations', component: SubscriptionDialogAnnotations },
            { path: 'virtualtags', component: SubscriptionDialogVirtualTags }
        ]
    }
];

const cloudProviderDialogChildRoutes = [
    {
        path: '',
        component: Passthrough,
    },
    {
        path: ':cloudProviderId',
        component: CloudProviderDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: CloudProviderDialogUsage }
        ]
    }
];

const cloudAccountDialogChildRoutes = [
    {
        path: '',
        component: CloudAccountDialog,
        children: [
            {
                path: ':cloudAccountId',
                component: CloudAccountDialogIndex,
                children: [
                    { path: '', redirect: 'usage' },
                    { path: 'usage', component: CloudAccountDialogUsage },
                    { path: 'trends', component: CloudAccountDialogTrends },
                    { path: 'copies', component: CloudAccountDialogCopies },
                    { path: 'annotations', component: CloudAccountDialogAnnotations },
                    { path: 'virtualtags', component: CloudAccountDialogVirtualTags },
                    { path: 'carbon', component: CloudAccountDialogCarbon }
                ]
            }
        ]
    }
];

const actionDialogChildRoutes = [
    {
        path: '',
        component: ActionDialog,
        children: [
            {
                path: ':actionId',
                component: ActionDialogIndex,
                children: [
                    { path: '', redirect: 'versions' },
                    { path: 'versions', component: ActionDialogVersions },
                    { path: 'accounts', component: ActionDialogAccounts },
                ]
            }
        ]
    }
];

const productDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: ':productId',
        component: ProductDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: ProductDialogUsage },
            { path: 'carbon', component: ProductDialogCarbon },
            { path: 'trends', component: ProductDialogTrends }
        ]
    }
];

const reservationDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: ':reservationId',
        component: ReservationDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: ReservationDialogUsage }
        ]
    }
]

const customViewDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: ':customViewId',
        component: CustomViewDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: CustomViewDialogUsage },
        ]
    }
];

const regionDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: ':regionId',
        component: RegionDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: RegionDialogUsage },
            { path: 'carbon', component: RegionDialogCarbon }
        ]
    }
];

const geographyDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: ':geographyId',
        component: GeographyDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: GeographyDialogUsage },
            { path: 'carbon', component: GeographyDialogCarbon }
        ]
    }
];

const productCategoryDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: ':productCategoryId',
        component: ProductCategoryDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: ProductCategoryDialogUsage }
        ]
    }
];

const tagDialogChildRoutes = [
    { path: '', component: Passthrough },
    {
        path: 'tag/:tagId',
        component: TagDialogIndex,
        children: [
            { path: '', redirect: 'usage' },
            { path: 'usage', component: TagDialogUsage },
            { path: 'carbon', component: TagDialogCarbon },
            { path: 'trends', component: TagDialogTrends }
        ]
    }
];

const copyCloudAccountChildRoutes = [
    { path: '', redirect: 'chooseTenant' },
    { path: 'chooseTenant', component: CopyCloudAccountChooseTenant, meta: { step: 1 } },
    { path: 'pricingRules', component: CopyCloudAccountPricingRules, meta: { step: 2 } },
    { path: 'tags', component: CopyCloudAccountTags, meta: { step: 3 } },
    { path: 'finish', component: CopyCloudAccountFinish, meta: { step: 4 } }
];

const standardEntityDialogSuffixes = [
    {
        path: 'services',
        component: ServiceDialog,
        children: serviceDialogChildRoutes
    },
    {
        path: 'subscriptions',
        component: SubscriptionDialog,
        children: subscriptionDialogChildRoutes
    },
    {
        path: 'cloudaccounts',
        component: CloudAccountDialog,
        children: cloudAccountDialogChildRoutes
    },
    {
        path: 'cloudproviders',
        component: CloudProviderDialog,
        children: cloudProviderDialogChildRoutes
    },
    {
        path: 'products',
        component: ProductDialog,
        children: productDialogChildRoutes
    },
    {
        path: 'regions',
        component: RegionDialog,
        children: regionDialogChildRoutes
    },
    {
        path: 'geographies',
        component: GeographyDialog,
        children: geographyDialogChildRoutes
    },
    {
        path: 'tagkeys/:tagkey',
        component: TagDialog,
        children: tagDialogChildRoutes
    },
    {
        path: 'productcategories',
        component: ProductCategoryDialog,
        children: productCategoryDialogChildRoutes
    },
    {
        path: 'customviews',
        component: CustomViewDialog,
        children: customViewDialogChildRoutes
    },
    {
        path: 'reservation',
        component: ReservationDialog,
        children: reservationDialogChildRoutes
    }
];

const mainViewChildren = [
    { path: '', redirect: Config.isFeatureEnabled('Dataset.Usage') ? 'finance' : 'emissions/overview' },
    { path: 'overview', redirect: Config.isFeatureEnabled('Dataset.Usage') ? 'finance' : 'emissions/overview' },
    { path: 'dashboarding', component: CustomDashboardsIndex },
    { path: 'dashboarding/:id', name: 'customdashboard', component: CustomDashboardsDashboard },
    {
        path: 'finance',
        name: PATH_RESOURCES.names.dash,
        component: FinanceDashboard,
        children: [
            { path: '', component: Passthrough },
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'emissions/overview',
        name: 'emissions',
        component: CarbonOverview,
        children: [
            { path: '', component: Passthrough },
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'emissions/explore',
        name: 'emissionsexplore',
        component: CarbonExplorer,
        children: [
            { path: '', redirect: 'products' },
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'emissions/map',
        name: 'emissionsmap',
        component: CarbonMap,
        children: [
            { path: '', component: Passthrough },
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'trends',
        name: PATH_RESOURCES.names.trends,
        component: TrendsDashboard,
        children: [{ path: '/', component: Passthrough }, ...standardEntityDialogSuffixes]
    },
    {
        path: 'budgets',
        component: Budgets,
        children: [
            { path: '', component: Passthrough },
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'budgets/details/:id',
        component: BudgetDetails
    },
    {
        path: 'watchdog',
        component: WatchdogDashboard,
    },
    {
        path: 'watchdog/:id',
        component: WatchdogViewPolicy
    },
    {
        path: 'watchdog/:id/edit',
        component: WatchdogNewPolicy
    },
    {
        path: 'watchdog/:id/run/:rid',
        component: WatchdogViewPolicyRun
    },
    {
        path: 'new/watchdog',
        component: WatchdogNewPolicy
    },
    {
        path: 'kubernetes',
        component: KubernetesDashboard,
        children: [
            { path: '', component: Passthrough },
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'reservations',
        name: PATH_RESOURCES.names.reservations,
        component: ReservationsDashboard,
        children: [
            { path: '', component: ReservationDialog, children: [...reservationDialogChildRoutes] },
        ]
    },
    {
        path: 'custom/reports',
        name: 'customreports',
        component: CustomReports
    },
    {
        path: 'create/custom/reports',
        component: CreateReport
    },
    {
        path: 'edit/custom/reports/:id',
        component: EditReport
    },
    {
        path: 'clone/custom/reports/:id',
        component: CloneReport
    },
    {
        path: 'custom/reports/:id',
        name: 'customreport',
        component: CustomReport,
        children: [
            { path: '', component: Passthrough },
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'copies/usage',
        redirect: 'customers/usage'
    },
    {
        path: 'customers/usage',
        name: PATH_RESOURCES.names.customersUsageDashboard,
        component: CustomersUsageView
    },
    {
        path: 'customers/manage',
        name: PATH_RESOURCES.names.customersManage,
        component: CustomersManageView
    },
    {
        path: 'customers/manage/:id',
        name: PATH_RESOURCES.names.customerManage,
        component: CustomerManageView
    },
    {
        path: 'customers/manage/:tenantId/shareddata',
        name: PATH_RESOURCES.names.createEditSharedData,
        component: CopyCloudAccountIndex,
        children: copyCloudAccountChildRoutes
    },
    {
        path: 'recommendations',
        name: PATH_RESOURCES.names.recommendations,
        component: RecommendationsView
    },
    {
        path: 'tagoverview',
        name: PATH_RESOURCES.names.tagoverview,
        component: TagDashboardView
    },
    {
        path: 'regionoverview',
        name: PATH_RESOURCES.names.regionoverview,
        component: RegionOverviewView,
        children: [
            ...standardEntityDialogSuffixes
        ]
    },
    {
        path: 'getting-started',
        name: 'gettingstarted',
        component: GettingStarted
    },
    {
        path: 'cloud-intelligence',
        name: 'gptChats',
        component: GptChats,
        meta: { hideMain: true }
    },
    {
        path: 'copy/cloudaccounts/:cloudAccountId',
        name: PATH_RESOURCES.names.copyCloudAccount,
        component: CopyCloudAccountIndex,
        children: copyCloudAccountChildRoutes
    },
    {
        path: 'actions/:actionId',
        name: 'actionDetails',
        component: ActionIndex,
        children: [
            { path: '', redirect: 'develop' },
            { path: 'develop', component: ActionDevelop },
            { path: 'accounts', component: ActionCloudAccounts },
            { path: 'versions', component: ActionVersions },
        ]
    },
    {
        path: 'customviews',
        name: 'customViews',
        component: CustomViews,
    },
    {
        path: 'customviews/:id',
        name: 'customview',
        component: CustomView,
        children: [
            { path: '', redirect: 'overview' },
            { path: 'overview', component: CustomViewOverview},
            { path: 'usage', component: CustomViewDialogUsage}
        ]
    },
    {
        path: 'settings',
        name: PATH_RESOURCES.names.settings,
        component: Settings,
        children: [
            { path: '', redirect: 'general' },
            {
                path: 'amazon',
                component: Amazon,
                children: cloudAccountDialogChildRoutes
            },
            {
                path: 'azure',
                component: Azure,
                children: cloudAccountDialogChildRoutes
            },
            {
                path: 'google',
                component: Google,
                children: cloudAccountDialogChildRoutes
            },
            {
                path: 'oracle',
                component: Oracle,
                children: cloudAccountDialogChildRoutes
            },
            {
                path: 'alibaba',
                component: Alibaba,
                children: cloudAccountDialogChildRoutes
            },
            {
                path: 'snowflake',
                component: Snowflake,
                children: cloudAccountDialogChildRoutes
            },
            {
                path: 'custom',
                component: Custom
            },
            {
                path: 'users',
                name: PATH_RESOURCES.names.users,
                component: Users
            },
            {
                path: 'billing',
                name: PATH_RESOURCES.names.billingdetails,
                component: BillingDetails
            },
            {
                path: 'apimanagement',
                name: PATH_RESOURCES.names.ApiManagement,
                component: ApiManagementView
            },
            {
                path: 'general',
                name: PATH_RESOURCES.names.tenants,
                component: General
            },
            {
                path: 'actions',
                name: PATH_RESOURCES.names.actions,
                component: Actions,
                children: actionDialogChildRoutes
            },
            {
                path: 'fxrates',
                name: PATH_RESOURCES.names.fxrates,
                component: FxRates
            },
            {
                path: 'tagmappings',
                name: 'tagmappings',
                component: TagMappings
            },
            {
                path: 'mailinglists',
                name: 'mailinglists',
                component: MailingLists
            }
        ]
    },
    {
        path: 'resources',
        redirect: 'explore'
    },
    {
        path: 'services',
        redirect: '/explore/services'
    },
    {
        path: 'explore',
        component: ResourceExplorerIndex,
        children: [
            { path: '', redirect: 'products' },
            ...standardEntityDialogSuffixes,
            {
                path: 'tagkeys/:tagkey',
                component: Passthrough
            }
        ]
    },
    {
        path: '404',
        name: 'Error404',
        component: NotFound,
        meta: { allowNoTenant: true }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            meta: { allowNoTenant: true, allowAnonymous: true },
            async beforeEnter(to, from, next) {
                const auth0 = await getInstance();
                await auth0.loginWithRedirect();
                await auth0.handleRedirectCallback();
            }
        },
        {
            path: '/register',
            redirect: '/login',
            meta: { allowNoTenant: true, allowAnonymous: true }
        },
        {
            path: '/logout',
            name: PATH_RESOURCES.names.logout,
            meta: { allowNoTenant: true, allowAnonymous: true },
            async beforeEnter(to, from, next) {
                const auth0 = await getInstance();
                EventBus.$emit('$identity.authenticated', false);
                Raygun.endUserSession();
                let logoutUrl = window.location.protocol + "//" + window.location.host;
                if (window.location.host === 'localhost') logoutUrl = logoutUrl + ':' + window.location.port;
                await auth0.logout({ returnTo: logoutUrl });
                next('/');
            }
        },
        {
            path: '/choose',
            name: PATH_RESOURCES.names.choose,
            component: ChooseView,
            meta: { allowNoTenant: true }
        },
        {
            path: '/selfservice/create',
            name: PATH_RESOURCES.names.selfServiceCreate,
            component: SelfServiceTenantCreate,
            meta: { allowNoTenant: true },
            async beforeEnter(to, from, next) {
                if (!Config.isSelfServiceEnabled()) {
                    next('/');
                } else {
                    next();
                }
            }
        },
        {
            path: '/selfservice/plans',
            name: PATH_RESOURCES.names.selfServicePlans,
            component: SelfServicePlans
        },
        {
            path: '/',
            component: MainView,
            children: mainViewChildren,
        },
        {
            path: '/:baseTenantId',
            component: MainView,
            children: mainViewChildren,
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(r => r.path === '/404' )) {
        // todo: log 404 somewhere
    }
    
    // Apply the tenant Id in the path
    if(to.params.baseTenantId) {
        // if it is not valid force move to root
        if(uuidValidate(to.params.baseTenantId)) {
            AuthClient.tenantId = to.params.baseTenantId;
        } else {
            next('/');
            return;
        }
    } else if (to.meta?.allowNoTenant !== true && AuthClient.tenantId && !to.fullPath.includes(AuthClient.tenantId)) {
       next(`/${AuthClient.tenantId}${to.fullPath}`);
       return;
    }

    // Check for tenant context first, even if unauthenticated as next block will then force login.
    if (!AuthClient.tenantId && to.meta?.allowNoTenant !== true) {
        next('/choose');
        return;
    }

    if (to.meta?.allowAnonymous !== true) {
        authGuard(to, from, next);
        return;
    }

    next();
    return;
});
export default router;
