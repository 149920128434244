import Dexie, { Table } from 'dexie';
import { stringToUint, blobToString } from '@/lib/Uint';

export interface IKeyedBlobModel {
    key: string;
    value: Blob;
}

export class KeyedBlobsDb extends Dexie {
    blobs!: Table<IKeyedBlobModel>;
    constructor() {
        super('keyedBlobs');
        this.version(1).stores({
            blobs: 'key',
        });
    }

    async listBlobsWithPrefix(prefix: string): Promise<[string, Blob][]> {
        const blobs = await this.blobs.where('key').startsWith(prefix).toArray();
        return blobs.map((b) => [b.key, b.value]);
    }

    async getBlob(key: string): Promise<Blob | null> {
        return await this.blobs.get(key).then((r) => r?.value || null);
    }

    async putBlob(key: string, value: Blob): Promise<void> {
        await this.blobs.put({ key, value }, key);
    }

    async listDocsWithPrefix<TType>(prefix: string): Promise<[string, TType][]> {
        const blobs = await this.listBlobsWithPrefix(prefix);
        const docs: [string, TType][] = [];
        for (const [key, blob] of blobs) {
            const buffer = await blob.arrayBuffer();
            const array = new Uint8Array(buffer).toString();
            const doc = JSON.parse(array) as TType;
            docs.push([key, doc]);
        }
        return docs;
    }

    async getDoc<TType>(key: string): Promise<TType | null> {
        const blob = await this.getBlob(key);
        if (!blob) return null;
        const stringBlob = (await blobToString(blob)) as string;

        return JSON.parse(stringBlob);
    }

    async putDoc<TType>(key: string, doc: TType): Promise<void> {
        const array = await stringToUint(JSON.stringify(doc));
        const blob = new Blob([array]);
        await this.putBlob(key, blob);
    }

    async changeDoc<TType>(
        key: string,
        p: { changeFn?: (doc: TType) => TType | void; initFn: () => TType }
    ): Promise<TType> {
        // TODO: Want to use dexie tx's just hitting issue w/ `blob.arrayBuffer` promise.
        const { changeFn, initFn } = p;

        let doc1: TType = await this.getDoc<TType>(key),
            doc2 = doc1;

        let inited = false;
        if (!doc1) {
            doc1 = initFn();
            inited = true;
        }
        let changed = false;
        if (changeFn) {
            const result = changeFn(doc1);
            if (result) {
                doc2 = result;
                changed = true;
            }
        }
        if (inited || (doc2 && changed)) {
            await this.putDoc(key, doc2 || doc1);
        }
        const final = doc2 || doc1;
        return final;
    }
}

export default new KeyedBlobsDb();
