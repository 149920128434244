export class RaygunClient {
    private static isUserEmailSet: boolean;

    public setUserEmail(userEmail: string) {
        // It doesn't really matter if we set the email again and again.
        // We may as well avoid repeating the function call by doing this check, just in case it has more side affects than we know about.
        // But we definitely don't care about race condiations here.
        (window as any).rg4js('setUser', {
            identifier: userEmail,
            isAnonymous: false
        });
    }

    public sendError(err: any, info: any) {
        (window as any).rg4js('send', {
            error: err,
            customData: [{ info }]
        });
    }

    public setCorrelationId(correlationId: string) {
        (window as any).rg4js('withCustomData', { cloudCtrlCorrelationId: correlationId });
    }

    public trackPageView(newPath: string) {
        (window as any).rg4js('trackEvent', {
            type: 'pageView',
            path: newPath
        });
    }

    public endUserSession() {
        (window as any).rg4js('endSession', {});

        (window as any).rg4js('setUser', {
            isAnonymous: true
        });
        RaygunClient.isUserEmailSet = false;
    }
}

export default new RaygunClient();
