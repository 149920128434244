
import Config from '@/lib/Config';
import { defineComponent } from 'vue';
const DEFAULT_SESSION_NAME = 'CloudCostAgent';
const bucketNameRegex = /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/;
export default defineComponent({
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            docsUrl: Config.get().DOCS_URL,
            name: '',
            useTenantCurrencies: false,
            externalId: '',
            roleArn: '',
            sessionName: DEFAULT_SESSION_NAME,
            s3BucketName: '',
            s3BucketNameRules: [
                (v) => !!v || this.$t('setAmazonAssumeRoleCredentialsCard.s3BucketName.required'),
                (v) => bucketNameRegex.test(v) || this.$t('setAmazonAssumeRoleCredentialsCard.s3BucketName.notBucketName'),
            ],
            regionCode: ''
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                if (val.awsAssumeRoleCredential) {
                    this.externalId = val.awsAssumeRoleCredential.clientDomain;
                    this.roleArn = val.awsAssumeRoleCredential.providerId;
                    this.sessionName = val.awsAssumeRoleCredential.token;
                    this.regionCode = val.awsAssumeRoleCredential.regionCode;
                    this.s3BucketName = val.awsAssumeRoleCredential.container;
                } else {
                    this.externalId = '';
                    this.roleArn = '';
                    this.sessionName = DEFAULT_SESSION_NAME
                    this.s3BucketName = '';
                    this.regionCode = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        tryPrefillExternalId() {
            if (this.externalId) return;
            if (!this.roleArn) return;
            if (!this.roleArn.startsWith('arn:aws:iam::')) return;
            const [,,,,externalId] = this.roleArn.split(':');
            if (!externalId) return;
            this.externalId = externalId;
        }
    }
});
