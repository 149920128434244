
import SpendUsageBarChart, {
    convertProjectionsResponseToDataset,
    convertUsageResponseToDatasets,
    IClickEvent,
    UsageReportTypes,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import {
    SpendUsageOptions,
    convertSpendOptionsToQuery,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import { filterUsageParamsByFilterChips, IChipViewModel } from '@/components/Domain.Usage/UsageFilterChips.vue';
import { toastError } from '@/components/Common/Toast.vue';
import BudgetsTable from '@/components/Domain.Budgets/BudgetsTable.vue';
import {
    PluralEntityType,
    ICustomViewResult,
    Granularity,
    IBudgetResult,
    ISpendUsageTableRecordsViewModel,
    SpendFieldNames,
} from '@/models';
import { appendValueToCommaString } from '@/lib';
import EntityType, { fromPlural } from '@/models/EntityType';
import { useTenantStore, useWaitStore, storeToRefs } from '@/stores';
import { mapStores } from 'pinia';
import { getSpend, getForecast } from '@/lib/Api';
import { defineComponent, ref, watch } from 'vue';
import { i18n } from '@/i18n';
import * as Api from '@/lib/Api';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';

export default defineComponent({
    data() {
        return { isShowingReport: false, customViews: [], isCreating: false, customViewsLoading: true };
    },
    components: { SpendUsageBarChart, BudgetsTable },
    props: { value: Object, loading: Boolean },
    computed: {
        ...mapStores(useTenantStore),
        routePrefix() {
            return `/customViews/${this.value.id}`;
        },
    },
    setup(p) {
        const budgets = ref<IBudgetResult[]>([]);
        const loadBudgets = async () =>
            await whilst('gettingBudgets', async function () {
                const view = p.value as ICustomViewResult;
                budgets.value = await Api.getCustomViewBudgets(view.id);
            });

        const { currencyCode } = storeToRefs(useTenantStore());
        const { is, whilst } = useWaitStore();
        const spendOptions = ref<SpendUsageOptions>({
            dateRangeKey: '13m',
            granularity: Granularity.monthly,
            topXResults: 10,
        });
        const newChartData = ref({ labels: [], datasets: [] });
        const usageTableItems = ref<ISpendUsageTableRecordsViewModel | null>(null);
        const filters = ref<IChipViewModel[]>([]);
        const averageMonthlySpend = { value: 0.0 };
        const loadTabUsage = async () =>
            await whilst('getAccountUsage', async () => {
                const view = p.value as ICustomViewResult;
                let params = convertSpendOptionsToQuery(spendOptions.value);
                if (view.cloudAccountIds.length) {
                    params.cloudAccountIds = appendValueToCommaString(
                        params.cloudAccountIds,
                        view.cloudAccountIds.map((a) => a.id)
                    );
                }
                if (view.cloudProviderTypes.length) {
                    params.providerType = appendValueToCommaString(params.providerType, view.cloudProviderTypes);
                }
                if (view.geographies.length) {
                    params.geographyNames = appendValueToCommaString(params.geographyNames, view.geographies);
                }
                if (view.productCategories.length) {
                    params.productCategories = appendValueToCommaString(
                        params.productCategories,
                        view.productCategories.map((p) => `"${p}"`)
                    );
                }
                if (view.regions.length) {
                    params.regionNames = appendValueToCommaString(params.regionNames, view.regions);
                }
                if (view.subscriptionIds) {
                    params.subscriptionIds = appendValueToCommaString(
                        params.subscriptionIds,
                        view.subscriptionIds.map((s) => s.id)
                    );
                }
                if (view.sysProductIds) {
                    params.productIds = appendValueToCommaString(
                        params.productIds,
                        view.sysProductIds.map((p) => p.id)
                    );
                }
                if (view.sysServiceIds) {
                    params.serviceIds = appendValueToCommaString(
                        params.serviceIds,
                        view.sysServiceIds.map((s) => s.id)
                    );
                }
                if (view.tagKeyValues) {
                    params.tagKeyValue = appendValueToCommaString(params.tagKeyValue, view.tagKeyValues);
                }
                const segregateBy = spendOptions.value.segregateBy as PluralEntityType;
                params = filterUsageParamsByFilterChips(filters.value, params);
                if (!params) return;
                const [usageResponse, projectionsResponse] = await Promise.all([
                    getSpend({
                        ...params,
                        segregateBy: spendOptions.value.segregateBy,
                        fields: [SpendFieldNames.Charges],
                    }),
                    getForecast(params, spendOptions.value.dateRangeKey),
                ]);
                const usageDataset = convertUsageResponseToDatasets(usageResponse, {
                    reportType: UsageReportTypes.charges,
                });
                const forecastDataset = convertProjectionsResponseToDataset(projectionsResponse);
                newChartData.value = {
                    labels: [...forecastDataset.labels, ...usageDataset.labels],
                    datasets: [...forecastDataset.datasets, ...usageDataset.datasets],
                };
                usageTableItems.value = SpendUsageTableFunctions.convertToItemRecords(
                    params,
                    segregateBy,
                    usageResponse,
                    spendOptions.value.costView
                );
                averageMonthlySpend.value = usageResponse.recordAverage || 0.0;
            });

        const handleDatasetClicked = ({ isOther, name, datasetGroupId }: IClickEvent) => {
            if (isOther) {
                spendOptions.value.topXResults = spendOptions.value.topXResults + 10;
            } else if (spendOptions.value.segregateBy === PluralEntityType.meters) {
                toastError(i18n.t('shared.meterInformationNotSupported'));
            } else {
                filters.value.push({
                    id: datasetGroupId,
                    name,
                    type: fromPlural(spendOptions.value.segregateBy) as EntityType,
                });
            }
        };

        watch(
            () => [p.value, spendOptions.value, filters.value],
            () => {
                if (!p.value || !spendOptions.value || is('getAccountUsage')) return;
                loadTabUsage();
                loadBudgets();
            },
            { immediate: true, deep: true }
        );

        return {
            spendOptions,
            newChartData,
            usageTableItems,
            filters,
            loadTabUsage,
            handleDatasetClicked,
            averageMonthlySpend,
            currencyCode,
            wait: { is },
            budgets,
            loadBudgets,
        };
    },
});
