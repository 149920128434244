import Vue from 'vue';
import Raygun from '@/lib/Raygun';
import Config from '@/lib/Config';
import router from '@/plugins/router';
import { v4 } from 'uuid';
import { ApplicationInsights, IConfiguration, IConfig } from '@microsoft/applicationinsights-web';

let correlationId: string;

function nextCorrelationId() {
    correlationId = v4();
}

const appInsightsKey = Config.get().APP_INSIGHTS_KEY;
let appInsights: ApplicationInsights;

if (appInsightsKey) {
    const appInsightsConfig: IConfiguration & IConfig = {
        instrumentationKey: appInsightsKey,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
        correlationHeaderExcludedDomains: ['cloudctrl.au.auth0.com', 'auth.cloudctrl.com.au', 'fonts.googleapis.com', 'spendmanager.au.auth0.com']
    };
    const telemetryInitializer = envelope => {
        envelope.data.cloudCtrlCorrelationId = correlationId;
    };
    appInsights = new ApplicationInsights({ config: appInsightsConfig });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telemetryInitializer);
}

Vue.config.errorHandler = (err, _, info) => {
    Raygun.setCorrelationId(correlationId);
    Raygun.sendError(err, info);
    throw err;
};

router.afterEach(to => {
    Raygun.setCorrelationId(correlationId);
    if (Vue.prototype.$auth.isAuthenticated) {
        Raygun.setUserEmail(Vue.prototype.$auth.user.email);
    }
    Raygun.trackPageView(to.path);
});

router.beforeEach((to, from, next) => {
    nextCorrelationId();
    next();
});

export default router;
