<template>
    <v-menu offset-y top>
        <template #activator="{ on }">
            <v-list>
                <v-list-item v-on="on">
                    <v-list-item-avatar>
                        <v-avatar :size="40" color="grey lighten-4" v-if="email">
                            <img :src="gravatarUrl" alt="avatar" />
                        </v-avatar>
                        <v-avatar :size="40" color="grey lighten-4" v-else>
                            <img src="http://www.gravatar.com/avatar/?d=identicon" alt="avatar" />
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ value ? value.Name : '...' }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="email">
                            {{ email }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else> Your Profile </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
        <v-list light subheader>
            <v-subheader>{{ $t('navUserMenu.title') }}</v-subheader>
            <v-list-item @click.stop="toggleDarkTheme()">
                <v-list-item-content> Dark Theme: {{ $vuetify.theme.dark ? 'ON' : 'OFF' }} </v-list-item-content>
                <v-list-item-icon>
                    <v-icon>{{ $vuetify.theme.dark ? 'brightness_medium' : 'brightness_high' }} </v-icon>
                </v-list-item-icon>
            </v-list-item>
            <form-dialog v-model="isEditingNotifications">
                <template #activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-content>
                            <v-list-item-title>Notification Settings</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>notifications</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </template>
                <template #default="{ close }">
                    <form-card
                        title="Notification Settings"
                        subtitle="Notification settings for all of your tenants and customers."
                    >
                        <profile-tenants-table
                            :items="profiles"
                            :loading="$wait.is('loadingProfiles')"
                            :fields="['name', 'SubscribeToAlerts', 'SubscribeToSpendSummaries']"
                        ></profile-tenants-table>
                        <template #actions>
                            <back-button @click="close()"></back-button>
                        </template>
                    </form-card>
                </template>
            </form-dialog>
            <v-divider></v-divider>
            <v-list-item to="/logout">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navUserMenu.logOut') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<i18n>
{
    "en": {
        "navUserMenu.title": "User Menu",
        "navUserMenu.changePassword": "Change Password",
        "navUserMenu.logOut": "Log Out"
    }
}
</i18n>

<script>
import Raygun from '@/lib/Raygun';
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import ProfileTenantsTable from '@/components/Account.ManageTenants/ProfileTenantsTable.vue';
import MD5 from '@/lib/MD5';

export default {
    props: ['size', 'value'],
    components: { ProfileTenantsTable },
    data() {
        return {
            email: this.$auth.user.email,
            isEditingNotifications: false,
            profiles: [],
        };
    },
    created() {
        this.loadProfiles();
    },
    computed: {
        gravatarUrl() {
            if (!this.email) return null;
            const md5 = MD5(this.email.toLowerCase());
            let url = '//www.gravatar.com/avatar/' + md5;
            if (this.size) url = url + '?s=' + this.size + '&d=identicon';
            return url;
        },
    },
    methods: {
        toggleDarkTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            if (this.$vuetify.theme.dark) {
                localStorage.setItem('dark_theme', 'true');
            } else {
                localStorage.setItem('dark_theme', 'false');
            }
        },
        loadProfiles: waitFor('loadingProfiles', async function () {
            this.profiles = await ApiV2.http.get('/api/accounts/me/profiles').then((r) => r.data);
        }),
    },
    watch: {
        '$auth.loading'(value) {
            if (value === false) {
                this.email = this.$auth.user.email;
                Raygun.setUserEmail(this.$auth.user.email);
            }
        },
        deep: true,
    },
};
</script>
