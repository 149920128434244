<template>
    <content-card
        :title="$t('copyCloudAccount.chooseTenant.title')"
        :caption="$t('copyCloudAccount.chooseTenant.caption')"
        :loading="loading"
    >
        <v-select
            :value="cloudAccount ? cloudAccount.id : null"
            :items="cloudAccounts"
            :disabled="editing"
            item-value="id"
            item-text="name"
            label="Cloud Connection"
            @input="$emit('changeCloudAccountId', $event)"
        ></v-select>
        <v-checkbox
            v-model="value.shareParentConnection"
            :label="$t('copyCloudAccount.chooseTenant.share')"         
        ></v-checkbox>
        <v-select
            :value="value.tenant.id"
            :items="tenants"
            :disabled="editing"
            item-value="tenantId"
            item-text="tenantName"
            label="Customer"
            @input="$emit('input', { ...value, tenant: { ...value.tenant, id: $event } })"
        ></v-select>
        <template #actions>
            <slot :isValid="value.tenant.id"></slot>
        </template>
    </content-card>
</template>

<i18n>
{
    "en": {
        "copyCloudAccount.chooseTenant.title": "Sharing",
        "copyCloudAccount.chooseTenant.caption": "Choose the cloud connection to copy into which customer.",
        "copyCloudAccount.chooseTenant.share": "Share cloud connection credentials for enhanced services e.g. Recommendations and reservations."
    }
}
</i18n>

<script>
export default {
    props: {
        value: Object,
        cloudAccountId: String,
        cloudAccounts: [],
        cloudAccount: Object,
        tenants: Array,
        loading: Boolean,
        editing: Boolean,
    },
    computed: {
        selectedTenantId() {
            if (!this.value || !this.value.tenant) return null;
            return this.value.tenant.id;
        },
        selectedCustomerTenants() {
            return this.tenants;
        },
    },
};
</script>