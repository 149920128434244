
import Downloader from "@/lib/Downloader";
import moment from "@/lib/moment";
import { IAllTagsRequestResult, ISpendByTagResult, ITagDimension } from "@/models";

export const convertAllTagsResultToLookup = (
  result: IAllTagsRequestResult
): { [tagId: string]: string } => {
  return result.data.reduce((obj, item) => {
    obj[`${item.key}:${item.value}`] = item.id;
    return obj;
  }, {});
};

export const convertSpendByTagResultToDimensions = (
  result: ISpendByTagResult
): ITagDimension[] => {
  if (!result?.data?.length) return [];
  return result.data[0].rowDimensions;
};

export interface ITagSpendTableItemViewModel {
  tagKey1: ITagDimension;
  tagKey2: ITagDimension | null;
  tagKey3: ITagDimension | null;

  figures: { [usageKey: string]: number };
}

export const convertSpendByTagResultToItems = (
  result: ISpendByTagResult
): ITagSpendTableItemViewModel[] => {
  if (!result?.data?.length) {
    return [];
  }
  const rowMap = new Map<string, ITagSpendTableItemViewModel>();
  result.data.forEach((item) => {
    const usageKey = item.usageDate.substring(0, 7);
    const rowKey = "".concat(
      item.rowDimensions[0].tagValue,
      item.rowDimensions[1] != null ? item.rowDimensions[1].tagValue : "_null",
      item.rowDimensions[2] != null ? item.rowDimensions[2].tagValue : "_null"
    );
    const figure = Math.round(item.totalSpend * 100) / 100;
    if (!rowMap.has(rowKey)) {
      const row1: ITagSpendTableItemViewModel = {
        tagKey1: item.rowDimensions[0],
        tagKey2: item.rowDimensions[1] != null ? item.rowDimensions[1] : null,
        tagKey3: item.rowDimensions[2] != null ? item.rowDimensions[2] : null,
        figures: { [usageKey]: figure },
      };
      rowMap.set(rowKey, row1);
    } else {
      const row = rowMap.get(rowKey);
      row.figures[usageKey] = figure;
    }
  });
  return [...rowMap.entries()].map(([, v]) => v);
};

export default {
  props: {
    items: Array,
    dimensions: Array,
    currencyCode: String,
    loading: Boolean,
  },
  computed: {
    usageHeaders() {
      return this.headers.filter((h) => !h.value.startsWith("tagKey"));
    },
    headers() {
      if (!this.dimensions || !this.items) return [];

      const headers = [];
      this.dimensions.forEach((d: ITagDimension | undefined | null, i: number) => {
        if (d) {
          headers.push({
            text: d.tagKey,
            value: `tagKey${i + 1}`,
            sortable: true,
            index: new Date(-8640000000000000 + i),
          });
        }
      });

      const headerMap = new Set();
      this.items.forEach((item: ITagSpendTableItemViewModel) => {
        Object.keys(item.figures).forEach((key) => {
          if (!headerMap.has(key)) {
            headerMap.add(key);
            headers.push({
              text: key,
              value: key,
              sortable: true,
              index: moment.utc(key).toDate(),
            });
          }
        });
      });
      return headers.sort((a, b) => a.index - b.index);
    },
  },
  methods: {
    exportCsv() {
      const headerValues: string[] = this.headers.map((i) => i.value);
      const csvValues: string[][] = this.items.map(
        (record: ITagSpendTableItemViewModel) =>
          headerValues.map((header: string) => {
            if (header.startsWith("tagKey")) {
              const dimension = record[header] as ITagDimension;
              return dimension.tagValue;
            } else {
              return record.figures[header];
            }
          })
      );
      csvValues.unshift(this.headers.map((i) => i.text));
      Downloader.exportCSV(csvValues, `TagOverview.csv`);
    },
    customSort(items, sortBy, sortDesc) {
      if (!sortBy || sortBy.length === 0) {
        return items;
      }
      return items.sort((a, b) => {
        let sortA, sortB;

        if (sortBy[0].startsWith("tagKey")) {
          sortA = a[sortBy[0]].tagValue;
          sortB = b[sortBy[0]].tagValue;
        } else {
          sortA = a.figures[sortBy[0]];
          sortB = b.figures[sortBy[0]];
        }

        const compareResult = sortA > sortB ? 1 : -1;

        return sortDesc[0] ? -compareResult : compareResult;
      });
    },
  },
};
