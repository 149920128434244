
import ApiV2 from '@/lib/ApiV2';
import { defineComponent, ref } from 'vue';
import { ITagMetadataResult } from '@/models';

export default defineComponent({
    setup(_, { emit }) {
        const showTagDialog = ref(false);
        const showTagId = ref<string | null>(null);
        const selectedTag = ref<ITagMetadataResult | null>(null);

        const tryCloseDialog = (isOpen: boolean) => {
            if (!isOpen) {
                showTagDialog.value = false;
                showTagId.value = null;
                selectedTag.value = null;
            }
            emit('input', isOpen);
        };

        const loadTagDetails = async (id: string) => {
            selectedTag.value = await ApiV2.http.get(`/api/metadata/tagkeyvalue/${id}`).then((r) => r.data);
        };

        return {
            showTagDialog,
            showTagId,
            selectedTag,
            tryCloseDialog,
            loadTagDetails,
        };
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showTagId === to.params.tagId) return;
                this.showTagId = to.params.tagId || null;
                this.showTagDialog = !!this.showTagId;
                if (this.selectedTag && this.selectedTag.tagKeyValue === this.showTagId) return;
                if (!this.showTagId) return;
                this.loadTagDetails(this.showTagId);
            },
            immediate: true,
            deep: true,
        },
    },
});
